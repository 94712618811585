import { toast } from "react-toastify";

const BASEURL = process.env.REACT_APP_BASE_URL;


export const URLSponsorType = `${BASEURL}/api/catalogs/sponsor-types`;
export const URLSpaceType = `${BASEURL}/api/catalogs/advertising-space-type`;
export const URLSpaceMaterial = `${BASEURL}/api/catalogs/advertising-space-material`;
export const URLAdvertisingSpaces = `${BASEURL}/api/advertising-spaces`;
export const URLRegister = `${BASEURL}/api/register`;

export const handleError = (error) => {
  console.error(error);
  toast(error, {
    autoClose: 3000,
    position: "top-right",
    style: { background: "#253a60", color: "white" },
    hideProgressBar: true,
  });
};

export const messageModal = (message) => {
  toast(message, {
    autoClose: 3000,
    position: "top-right",
    style: { background: "#253a60", color: "white" },
    hideProgressBar: true,
  });
};

export const requestMethod = (endpoint, data, method = "GET", obj) => {
  const url = `${BASEURL}${endpoint}`;
  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: obj,
      body: data,
    });
  }
};

export const request = (endpoint, data, method = "GET", obj) => {
  return fetch(endpoint, {
    method,
    headers: obj,
    body: data,
  });
};
export const requestMethodToken = (endpoint, method = "GET", obj) => {
  const url = `${BASEURL}${endpoint}`;

  if (method === "GET") {
    return fetch(url, obj);
  } else {
    return fetch(url, {
      method,
      headers: obj,
    });
  }
};
