import {
  requestMethod,
  requestMethodToken,
  handleError,
} from "../helpers/httpMethod";
import { authenticationUser } from "../types/authenticationUser";

export const startLogin = (email, password) => {
  const obj = {
    "Content-Type": "application/json",
  };
  const data = JSON.stringify({ email, password });
  return async (dispatch) => {
    const res = await requestMethod("/api/login", data, "POST", obj);
    const body = await res.json();

    if (body.error === false) {
      localStorage.setItem("token", body.data.token);
      localStorage.setItem("token-init-date", new Date().getTime());
      dispatch(
        login({
          user: body.data.user,
          token: body.data.token,
        })
      );
    } else {
      handleError(body.error);
    }
  };
};

export const startRefresToken = () => {
  const access_token = localStorage.getItem("token") || "";

  const obj = {
    Authorization: `Bearer  ${access_token}`,
  };

  return async (dispatch) => {
    const res = await requestMethodToken("/api/refresh", "POST", obj);
    const body = await res.json();

    if (body.error === false) {
      localStorage.setItem("token", body.data.token);
      localStorage.setItem("token-init-date", new Date().getTime());
      dispatch(
        login({
          user: body.data.user,
          token: body.data.token,
        })
      );
    } else {
      dispatch(checkingFinish());
    }
  };
};

const checkingFinish = () => ({
  type: authenticationUser.authCheckingFinish,
});

export const login = (user) => ({
  type: authenticationUser.authLogin,
  payload: user,
});

export const startLogout = (user) => {
  return (dispatch) => {
    localStorage.clear();
    dispatch(logout());
  };
};

const logout = () => ({
  type: authenticationUser.authLogout,
});
