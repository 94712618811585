import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { InputDate } from "../inputDate/InputDate";
import { availabilityDate } from "../../action/formActions";
import "./Availability.css";

const GreenRadio = withStyles({
  root: {
    color: "#9CAFC3",
    "&$checked": {
      color: "#64FF6C",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    color: "#FFFFFF",
    minWidth: "30%",
  },
}));

export const Avalability = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const availability = useSelector((state) => state.leagues.availability);

  const [value, setValue] = useState(availability.isAvailability);
  const [dateDisable, setDateDisable] = useState(null);
  const [selectedDate, setDateChange] = useState(availability.date);

  const handleDateChange = (datechange) => {
    setDateChange(datechange);
    dispatch(
      availabilityDate({
        isAvailability: value,
        date: datechange,
      })
    );
  };
  const handleRadioChange = ({ target }) => {
    if (target.value === "Si") {
      setValue(target.value);
      setDateDisable(true);
      dispatch(
        availabilityDate({
          isAvailability: target.value,
          date: "",
        })
      );
    } else {
      setValue(target.value);
      setDateDisable(false);
      dispatch(
        availabilityDate({
          isAvailability: target.value,
          date: selectedDate,
        })
      );
    }
  };

  return (
    <div className="container-availability">
      <FormControl component="div" className={classes.formControl}>
        <RadioGroup
          row
          aria-label="quiz"
          name="quiz"
          value={value}
          onChange={handleRadioChange}
          component="div"
        >
          <FormControlLabel
            value="Si"
            control={<GreenRadio />}
            label="Si"
            component="label"
          />
          <FormControlLabel
            value="No"
            control={<GreenRadio />}
            label="No"
            component="label"
          />
        </RadioGroup>
      </FormControl>
      <InputDate
        dateDisable={dateDisable}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};
