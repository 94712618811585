export const capitalize = (name) =>
  name.charAt(0).toUpperCase() + name.slice(1);

export const getGeolocation = ({ successCallback, errorCallback }) => {
  function success(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    successCallback({ lat, lng });
  }
  function error() {
    errorCallback("No se puede actualizar su ubicación");
  }
  
  if (!navigator.geolocation) {
    errorCallback("Su navegador no admite la geolocalización");
  } else {
    navigator.geolocation.getCurrentPosition(success, error);
  }
};

export const getCountry = (addressArray) => {
  const findCountry = addressArray.find((e) => e.types.includes("country"));
  const { long_name: country } = findCountry;

  return country;
};

export const getCity = (addressArray) => {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "administrative_area_level_2" === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const getArea = (addressArray) => {
  let area = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  }
};

export const getState = (addressArray) => {
  let state = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_1" === addressArray[i].types[0]
      ) {
        state = addressArray[i].long_name;
        return state;
      }
    }
  }
};
