import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "92%",
    maxWidth: "92%",
    maxheight: "8.2vh",
    minheight: "8.2vh",
    marginRight: "1em",
    "& > *": {
      background: "linear-gradient(to right bottom, #D4F123, #37F8A2)",
      color: "#253A60",
      borderRadius: "10px",
    },
  },

  root2: {
    minWidth: "92%",
    maxWidth: "92%",
    maxheight: "8.2vh",
    minheight: "8.2vh",
    marginRight: "1em",
    "& > *": {
      background: "#253A60",
      color: "#FFFFFF",
      borderRadius: "10px",
    },
  },
  btn: {
    minWidth: "100%",
    maxWidth: "100%",
    height: "8.2vh",
  },
}));

export const ButtonFilterAvaibility = ({
  handleSubmitAvaibility,
  isAvailability,
}) => {
  const classes = useStyles();

  return (
    <>
      {isAvailability === 1 ? (
        <div className={classes.root}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitAvaibility}
            className={classes.btn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8em",
                fontWeight: "bold",
              }}
            >
              Disponibles
            </div>
          </Button>
        </div>
      ) : (
        <div className={classes.root2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitAvaibility}
            className={classes.btn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8em",
                fontWeight: "bold",
              }}
            >
              No Disponibles
            </div>
          </Button>
        </div>
      )}
    </>
  );
};
