import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "../../hook/useForm";
import { startLogin } from "../../auth/authAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

function Copyright() {
  return (
    <Typography variant="body2" color="secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://pitz.app/">
        Pitz App
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Singup() {
  const history = useHistory();
  const hanclick = () => {
    history.push("/auth/singup");
  };

  return (
    <Typography
      variant="body2"
      component="span"
      color="secondary"
      align="center"
      style={{ cursor: "pointer" }}
    >
      <div color="inherit" onClick={hanclick}>
        Registrarse
      </div>
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: "#0c1e3b",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    margin: "15px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "linear-gradient(135deg, #d4f123 1.94%, #37f8a2 100%)",
  },
  root: {
    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
      backgroundColor: "#0C1E3B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
}));

export const Login = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const { email, password } = formValues;

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(startLogin(email, password));
  };

  return (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.img}>
            <img src="/img/logo.png" alt="logo pitz" />
          </div>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <form onSubmit={handleLogin} className={classes.form}>
            <TextField
              component="div"
              className={classes.root}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              value={email}
              onChange={handleInputChange}
              autoFocus
              autoComplete="off"
            />
            <TextField
              component="div"
              className={classes.root}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              value={password}
              onChange={handleInputChange}
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="off"
            />

            <Button
              component="button"
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Iniciar
            </Button>
          </form>
        </div>
        <Box mt={1} component="div">
          <Singup />
        </Box>
      </Container>
      <Box mt={1} component="div">
        <Copyright />
      </Box>
    </div>
  );
};
