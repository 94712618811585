import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "92%",
    maxHeight: "6vh",
    minHeight: "6vh",
    maxWidth: 500,
    backgroundColor: "#253A60",
    borderRadius: "10px",
    marginRight: "0.5em",
  },
  nested: {
    "& > *": {
      background: "#253A60",
      borderRadius: " 5px",
    },
  },

  btn: {
    width: "100%",
    height: "7vh",
    background: "none",
    paddingBottom: "12px",
    color: "white",
  },
  collapse: {
    fontSize: "0.75em",
    color: "#FFFFFF",
    minWidth: "100%",
    minHeight: "5vh",
    whiteSpace: "pre-line",
  },
}));

export const InputFilterType = ({ typeSpace, handleClickTypes }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      onBlur={() => handleClick()}
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      style={{ zIndex: "1" }}
    >
      <div className={classes.btn}>
        <Button
          className={classes.btn}
          onClick={handleClick}
          style={{ height: "7vh" }}
        >
          Tipos
        </Button>
      </div>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.nested}
      >
        <List component="div" disablePadding>
          {typeSpace.data.map((option) => (
            <ListItem
              button
              key={option.id}
              onFocus={(e) => {
                handleClick();
              }}
            >
              <div
                onClick={() => handleClickTypes(option.id)}
                className={classes.collapse}
              >
                {option.name}
              </div>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};
