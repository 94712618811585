import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { AppRouter } from "./routers/AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#9CAFC3",
      main: "#253A60",
      dark: "#0C1E3B",
      contrastText: "#fff",
    },
    secondary: {
      light: "#253A60",
      main: "#9CAFC3",
      dark: "#fff",
      contrastText: "#253A60",
    },
  },

  overrides: {
    MuiStepIcon: {
      text: {
        fill: "#0C1E3B",
      },
      root: {
        color: "#9CAFC3",
        "&$active": {
          color: "#9CAFC3",
        },
        "&$completed": {
          color: "#64FF6C",
        },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <header>
          <AppRouter />
          <ToastContainer />
        </header>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
