import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Grid, NativeSelect } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "3vh",
    minHeight: "3vh",
  },
  result: {
    color: "#9CAFC3",
    marginLeft: "1em",
    fontSize: "12px",
    marginTop: "0.5em",
  },
  formControl: {
    marginRight: "1em",
    marginTop: "0.5em",
    minWidth: 80,
    maxWidth: 120,
    color: "#FFFFFF",
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    color: "#FFFFFF",
    fontSize: "12px",
  },
}));

export const TotalResult = ({ infoSpace, setOrder, orderPrice }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    price: "",
  });

  const handleChange = (event) => {
    setOrder(event.target.value);
    setState({
      price: event.target.value,
    });
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{ white: "100%" }}
    >
      {orderPrice ? (
        <div className={classes.result}> {orderPrice.length} resultados </div>
      ) : (
        <div className={classes.result}>{infoSpace.length} resultados</div>
      )}

      <FormControl className={classes.formControl}>
        <NativeSelect
          className={classes.selectEmpty}
          value={state.price}
          name="price"
          onChange={handleChange}
          inputProps={{ "aria-label": "price" }}
        >
          <option
            value=""
            disabled
            style={{
              color: "#FFFFFF",
              background: "#0A1F3B",
              fontSize: "12px",
            }}
          >
            Ordenar por
          </option>
          <option
            value={"Precio más alto"}
            style={{
              color: "#FFFFFF",
              background: "#0A1F3B",
              fontSize: "12px",
            }}
          >
            Precio más alto
          </option>
          <option
            value={"Precio más bajo"}
            style={{
              color: "#FFFFFF",
              background: "#0A1F3B",
              fontSize: "12px",
            }}
          >
            Precio más bajo
          </option>
        </NativeSelect>
      </FormControl>
    </Grid>
  );
};
