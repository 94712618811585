import {
  contractTimeMax,
  contractTimeMin,
  monthlyFeeMax,
  monthlyFeeMin,
  spaceBase,
  spaceHeight,
  typeMaterial,
  typeSpace,
} from "../../action/formActions";
import { Avalability } from "../availability/Availability";
import { InputGeneral } from "../inputGeneral/InputGeneral";
import { InputNameSpace } from "../inputNameSpace/InputNameSpace";
import { InputPrice } from "../inputPrice/InputPrice";
import { InputTypeOfSpace } from "../inputTypeOfSpace/InputTypeOfSpace";
import { PhotoGallery } from "../photoGallery/PhotoGallery";
import { SponsorType } from "../sponsorType/SponsorType";

export function getStepContent(
  step,
  typeOfSpace,
  minimalPrice,
  maximumPrice,
  minimumDuration,
  maximumDuration,
  height,
  base,
  typeOfMaterial,
  optionsTypeSpace,
  optionsMaterial
) {
  switch (step) {
    case 0:
      return (
        <div>
          <InputTypeOfSpace
            selectDefault={"Selecciona una opción"}
            arrayOptions={optionsTypeSpace}
            valueForm={typeOfSpace}
            functionForm={typeSpace}
          />
          <InputNameSpace />
        </div>
      );

    case 1:
      return (
        <div>
          <InputPrice
            nameLabel={"Precio mínimo"}
            valueForm={minimalPrice}
            functionForm={monthlyFeeMin}
          />{" "}
          <InputPrice
            nameLabel={"Precio deseado"}
            valueForm={maximumPrice}
            functionForm={monthlyFeeMax}
          />{" "}
        </div>
      );
    case 2:
      return (
        <div>
          <InputGeneral
            nameLabel={"Duración mínima"}
            valueEnd={"meses"}
            inputType={"number"}
            valueForm={minimumDuration}
            functionForm={contractTimeMin}
          />{" "}
          <InputGeneral
            nameLabel={"Duración máxima"}
            valueEnd={"meses"}
            inputType={"number"}
            valueForm={maximumDuration}
            functionForm={contractTimeMax}
          />{" "}
        </div>
      );
    case 3:
      return (
        <div>
          <InputGeneral
            nameLabel={"Altura (longitud)"}
            valueEnd={"m"}
            inputType={"number"}
            valueForm={height}
            functionForm={spaceHeight}
          />{" "}
          <InputGeneral
            nameLabel={"Base (longitud)"}
            valueEnd={"m"}
            inputType={"number"}
            valueForm={base}
            functionForm={spaceBase}
          />{" "}
          <InputTypeOfSpace
            selectDefault={"Selecciona tipo de material"}
            arrayOptions={optionsMaterial}
            valueForm={typeOfMaterial}
            functionForm={typeMaterial}
          />
        </div>
      );
    case 4:
      return <PhotoGallery />;

    case 5:
      return <Avalability />;

    case 6:
      return <SponsorType />;

    default:
      return "";
  }
}
