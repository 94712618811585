import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { Button, FormControl, OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "92%",
    maxHeight: "6vh",
    minHeight: "6vh",
    maxWidth: 500,
    backgroundColor: "#253A60",
    borderRadius: "10px",
    marginRight: "0.5em",
  },
  nested: {
    "& > *": {
      background: "#253A60",
      borderRadius: " 5px",
    },
  },

  btn: {
    width: "100%",
    height: "8vh",
    background: "none",
    paddingBottom: "12px",
    color: "white",
  },
}));

export const ButtonFilterPrice = ({
  min,
  max,
  handleInputMinPrice,
  handleInputMaxPrice,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      onBlur={() => handleClick()}
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      style={{ zIndex: "1" }}
    >
      <div className={classes.btn}>
        <Button
          className={classes.btn}
          onClick={handleClick}
          style={{ height: "7vh" }}
        >
          Precio
        </Button>
      </div>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.nested}
      >
        <List component="div" disablePadding>
          <ListItem button>
            <div>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-basic"
                  type="number"
                  name="min"
                  value={min}
                  onChange={handleInputMinPrice}
                  placeholder="Min"
                  onFocus={(e) => {
                    handleClick();
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    shrink: "true",
                  }}
                  style={{
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    background: "#0A1F3B",
                  }}
                />
              </FormControl>
            </div>
          </ListItem>
          <ListItem button>
            <div>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-basic"
                  type="number"
                  name="max"
                  value={max}
                  onChange={handleInputMaxPrice}
                  placeholder="Max"
                  onFocus={(e) => {
                    handleClick();
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    shrink: "true",
                  }}
                  style={{
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    background: "#0A1F3B",
                  }}
                />
              </FormControl>
            </div>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
};
