import React, { useEffect, useState, useCallback } from "react";
import { Map } from "../map/Map";
import { Spinner } from "../spinner/Spinner";
import { getGeolocation } from "../../helpers/utils";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { handleError, URLRegister, request } from "../../helpers/httpMethod";
import { withStyles } from "@material-ui/styles";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { login } from "../../auth/authAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterFormSchema } from "../../formSchemas";
import { useHistory } from "react-router";

const GreenBox = withStyles({
  root: {
    color: "#9CAFC3",
    "&$checked": {
      color: "#64FF6C",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: "#0c1e3b",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2em",
  },
  img: {
    margin: "15px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "95%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: "100%",
    margin: theme.spacing(3, 0, 3),
    background: "linear-gradient(135deg, #d4f123 1.94%, #37f8a2 100%)",
  },
  root: {
    width: "100%",
    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
      backgroundColor: "#0C1E3B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
}));

export const ContainerSingup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialLocation, setInitialLocation] = useState({
    lat: 18.5204,
    lng: 73.8567,
    answerGeo: false,
  });
  const { handleSubmit, register, watch, errors, setValue } = useForm({
    resolver: yupResolver(RegisterFormSchema), mode: 'all' });
    
  const history = useHistory();

  const onPlaceChanged = useCallback((mapData) => {
    setValue('country', mapData.country);
    setValue('state', mapData.state);
    setValue('city', mapData.city);
    setValue('address', mapData.address);
    setValue('lat', mapData.lat);
    setValue('lng', mapData.lng);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(async (data) => {
    const formattedData = JSON.stringify({
      country: data.country,
      address: data.address,
      city: data.city,
      state: data.state,
      lat: data.lat,
      lng: data.lng,
      name: data.name,
      description: data.description,
      owner: {
        ...data.owner,
        confirmPassword: undefined
      },
      social_media: Object.keys(data.social).filter((key) => !key.includes('Url')).map((key) => ({
        social_media: data.social[key] ? key : '',
        link: data.social[key] ? data.social[`${key}Url`] || ''  : ''
      }))
    });

    try {
      const res = await request(
        URLRegister,
        formattedData, 
        "POST",
        { "Content-Type": "application/json" });
      const body = await res.json();
      if (body.error === false) {
        localStorage.setItem("token", body.data.token);
        localStorage.setItem("token-init-date", new Date().getTime());
        dispatch(
          login({
            user: body.data.user,
            token: body.data.token,
          })
        );
        return;
      } else {
        let errorMsg = body.message;

        if(body.errors["owner.email"]) {
          errorMsg = "El correo electrónico ya ha sido registrado"
        }

        return handleError(errorMsg);
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    // Obtenemos localización al iniciar el componente
    getGeolocation({
      successCallback: ({ lat, lng }) => {
        setInitialLocation({
          lat,
          lng,
          answerGeo: true
        })
        setValue('lat', lat);
        setValue('lng', lng);
      },
      errorCallback: (errorMsg) => {
        setInitialLocation((st) => ({
          ...st,
          answerGeo: true
        }));
        setValue('lat', initialLocation.lat);
        setValue('lng', initialLocation.lng);
        alert(errorMsg);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!initialLocation.answerGeo && <Spinner />}
      {initialLocation.answerGeo && (
        <div className={classes.container}>
          <Container
            component="main"
            maxWidth="md"
            style={{ minHeight: "90vh", background: "#9cafc318" }}
          >
            <CssBaseline />
            <div className={classes.paper}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{
                  color: "#FFFFFF",
                  width: "95%",
                  marginBottom: "0.5em",
                  marginTop: "0.5em",
                }}
              >
                <Typography
                variant="body2"
                component="span"
                color="secondary"
                align="left"
                style={{ cursor: "pointer", flexGrow: 1 }}
              >
                <div color="inherit" onClick={() => history.push('/auth/login')}>
                  Regresar a Iniciar sesión
                </div>
              </Typography>
              </Grid>
              
              <div className={classes.img}>
                <img src="/img/logo.png" alt="logo pitz" />
              </div>
              <Typography
                component="h1"
                variant="h5"
                style={{ color: "#FFFFFF" }}
              >
                Registro
              </Typography>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{
                  color: "#FFFFFF",
                  width: "95%",
                  marginBottom: "0.5em",
                  marginTop: "0.5em",
                }}
              >
                <Typography component="p" variant="button">
                  Ubicación del complejo
                </Typography>
              </Grid>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.form}
                autoComplete="off"
              >
                <input type="hidden" name="lat" ref={register} />
                <input type="hidden" name="lng" ref={register} />
               <Map
                  latitude={initialLocation.lat}
                  long={initialLocation.lng}
                  onPlaceChanged={onPlaceChanged}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="country"
                  InputLabelProps={{ shrink: Boolean(watch('country')) }}
                  //value={country}
                  //onChange={onChangeAndress}
                  //inputRef={testC}
                  label="País"
                  inputRef={register}
                  error={Boolean(errors?.country)}
                  helperText={errors?.country?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="state"
                  InputLabelProps={{ shrink: Boolean(watch('state')) }}
                  label="Estado"
                  inputRef={register}
                  error={Boolean(errors?.state)}
                  helperText={errors?.state?.message}
                />

                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="city"
                  InputLabelProps={{ shrink: Boolean(watch('city')) }}
                  label="Ciudad"
                  inputRef={register}
                  error={Boolean(errors?.city)}
                  helperText={errors?.city?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="address"
                  InputLabelProps={{ shrink: Boolean(watch('address')) }}
                  label="Dirección"
                  inputRef={register}
                  error={Boolean(errors?.address)}
                  helperText={errors?.address?.message}
                />
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    color: "#FFFFFF",
                    width: "95%",
                  }}
                >
                  <Typography component="p" variant="button">
                    Datos Personales
                  </Typography>
                </Grid>
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Nombre"
                  type="text"
                  name="owner.name"
                  inputRef={register}
                  error={Boolean(errors?.owner?.name)}
                  helperText={errors?.owner?.name?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Apellido"
                  type="text"
                  name="owner.lastname"
                  inputRef={register}
                  error={Boolean(errors?.owner?.lastname)}
                  helperText={errors?.owner?.lastname?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  id="outlined-basic"
                  margin="normal"
                  fullWidth
                  label="Correo electronico"
                  type="email"
                  name="owner.email"
                  inputRef={register}
                  error={Boolean(errors?.owner?.email)}
                  helperText={errors?.owner?.email?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Contraseña"
                  type="password"
                  name="owner.password"
                  inputRef={register}
                  error={Boolean(errors?.owner?.password)}
                  helperText={errors?.owner?.password?.message}
                />

                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Confirmación de contraseña"
                  type="password"
                  name="owner.confirmPassword"
                  inputRef={register}
                  error={Boolean(errors?.owner?.confirmPassword)}
                  helperText={errors?.owner?.confirmPassword?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Numero de Telefono"
                  type="number"
                  name="owner.phone"
                  inputRef={register}
                  error={Boolean(errors?.owner?.phone)}
                  helperText={errors?.owner?.phone?.message}
                  autoComplete="off"
                />
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    color: "#FFFFFF",
                    width: "95%",
                    marginTop: "0.5em",
                  }}
                  component="div"
                >
                  <Typography component="p" variant="button">
                    Liga
                  </Typography>
                </Grid>
                <TextField
                  component="div"
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Nombre de la liga"
                  type="text"
                  name="name"
                  inputRef={register}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
                <TextField
                  component="div"
                  className={classes.root}
                  style={{ marginTop: "0.5em" }}
                  id="outlined-multiline-static"
                  label="Descripción"
                  multiline
                  rows={3}
                  variant="outlined"
                  type="text"
                  name="description"
                  inputRef={register}
                  error={Boolean(errors?.description)}
                  helperText={errors?.description?.message}
                />
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    color: "#FFFFFF",
                    width: "95%",
                    marginTop: "1em",
                  }}
                  component="div"
                >
                  <Typography component="p" variant="button">
                    Redes Sociales
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  component="div"
                >
                  <FormControlLabel
                    style={{ width: "20%" }}
                    component="div"
                    control={<GreenBox />}
                    label={<span style={{ color: "#FFFFFF" }}>Facebook</span>}
                    labelPlacement="end"
                    type="checkbox"
                    name="social.facebook"
                    inputRef={register}
                  />

                  <TextField
                    style={{ width: "60%" }}
                    component="div"
                    className={classes.root}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Enlace"
                    type="text"
                    name="social.facebookUrl"
                    inputRef={register}
                    disabled={!watch('social.facebook')}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  component="div"
                >
                  <FormControlLabel
                    style={{ width: "20%" }}
                    component="div"
                    control={<GreenBox />}
                    label={<span style={{ color: "#FFFFFF" }}>Twitter</span>}
                    labelPlacement="end"
                    type="checkbox"
                    name="social.twitter"
                    inputRef={register}
                  />

                  <TextField
                    style={{ width: "60%" }}
                    component="div"
                    className={classes.root}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Enlace"
                    type="text"
                    name="social.twitterUrl"
                    inputRef={register}
                    disabled={!watch('social.twitter')}
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  component="div"
                >
                  <FormControlLabel
                    style={{ width: "20%" }}
                    component="div"
                    control={<GreenBox />}
                    label={<span style={{ color: "#FFFFFF" }}>Instagram</span>}
                    labelPlacement="end"
                    type="checkbox"
                    name="social.instagram"
                    inputRef={register}
                  />

                  <TextField
                    style={{ width: "60%" }}
                    component="div"
                    className={classes.root}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Enlace"
                    type="text"
                    name="social.instagramUrl"
                    inputRef={register}
                    disabled={!watch('social.instagram')}
                  />
                </Grid>

                <Button
                  component="button"
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  Enviar
                </Button>
              </form>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
