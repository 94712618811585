export const types = {
  typeSpace: "TYPE_OF_SPACE",
  monthlyFeeMin: "MONTHLY_FEE_MIN",
  monthlyFeeMax: "MONTHLY_FEE_MAX",
  contractTimeMin: "CONTRACT_TIME_MIN",
  contractTimeMax: "CONTRACT_TIME_MAX",
  spaceHeight: "CHARACTER_OF_SPACE_HEIGHT",
  spaceBase: "CHARACTER_OF_SPACE_BASE",
  typeMaterial: "TYPE_OF_METERIAL",
  imgGallery: "IMG_GALLERY",
  pictureGallery: "PICTURE_GALLERY",
  availability: "AVAILABILITY",
  sponsorType: "SPONSOR_TYPE",
  addSponsorType: "ADD_SPONSOR_TYPE",
  optionsSpaceType: "OPTIONS_SPACE_TYPE",
  optionsMaterialType: "OPTIONS_MATERIAL_TYPE",
  nameNewSpace: "NAME_NEW_SPACE",
  reset: "STATE_RESET",
};
