import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { FormControl, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#253a60",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    width: "98%",
    margin: theme.spacing(1),
    marginTop: "10px",
    borderRadius: "10px",
    boxShadow:
      "0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24)",
  },
  root: {
    color: "#ffffff",
    backgroundColor: "#253a60",
  },
}));

export const InputTypeOfSpace = ({
  selectDefault,
  arrayOptions,
  valueForm,
  functionForm,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currency, setCurrency] = React.useState(valueForm);
  const handleChange = ({ target }) => {
    setCurrency(target.value);
    dispatch(functionForm(target.value));
  };

  return (
    <FormControl className={classes.margin} component="div">
      <Select
        className={classes.root}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={currency}
        onChange={handleChange}
        input={<BootstrapInput />}
        component="div"
      >
        <MenuItem value={selectDefault} component="div">
          {selectDefault}
        </MenuItem>
        {arrayOptions.map((option) => (
          <MenuItem key={option.id} value={option.name} component="div">
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
