import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { nameNewSpace } from "../../action/formActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    width: "98%",
    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
      backgroundColor: "#0C1E3B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
}));

export const InputNameSpace = () => {
  const classes = useStyles();
  const nameSpace = useSelector((state) => state.leagues.nameSpace);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    valueNameSpace: nameSpace,
  });

  const { valueNameSpace } = values;
  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
    dispatch(nameNewSpace(target.value));
  };
  return (
    <div className={classes.root} autoComplete="off">
      <TextField
        required
        id="outlined-basic"
        label="Nombre de Espacio"
        variant="outlined"
        name="valueNameSpace"
        className={classes.root}
        value={valueNameSpace}
        onChange={handleInputChange}
      />
    </div>
  );
};
