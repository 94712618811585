import React from "react";
import {
  // DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

const useStylesReddit = makeStyles((theme) => ({
  root: {
    color: "white",
    minWidth: "60%",

    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
}));
export const InputDate = ({ dateDisable, selectedDate, handleDateChange }) => {
  const classes = useStylesReddit();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disabled={dateDisable}
        variant="inline"
        inputVariant="outlined"
        label="Fecha disponible"
        format="MM/dd/yyyy"
        value={selectedDate}
        // icon={<EventAvailableIcon />}
        InputAdornmentProps={{ position: "end" }}
        onChange={(date) => handleDateChange(date)}
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  );
};
