import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { PhotoModal } from "../photoModal/PhotoModal";

export const PhotoGallery = () => {
  const [cameraIcon] = useState(true);
  const [addIcon] = useState(false);

  return (
    <div>
      <Grid container spacing={1} component="div">
        <PhotoModal isCameraIcon={cameraIcon} index={0} />
        <PhotoModal isCameraIcon={addIcon} index={1} />
      </Grid>
      <Grid container spacing={1}>
        <PhotoModal isCameraIcon={addIcon} index={2} />
        <PhotoModal isCameraIcon={addIcon} index={3} />
      </Grid>
    </div>
  );
};
