import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1em",
    "& > *": {
      maxWidth: "100%",
      minWidth: "100%",
      background: "#253A60",
      color: "white",
      borderRadius: "15px",
    },

    iconButton: {
      padding: 10,
    },
  },
}));

export const InputSearch = ({ nameSpace, handleInputName }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl variant="outlined">
        <OutlinedInput
          id="outlined-basic"
          type="text"
          placeholder="Buscar por nombre"
          name="nameSpace"
          value={nameSpace}
          onChange={handleInputName}
          style={{ color: "#FFFFFF", borderRadius: "15px" }}
          startAdornment={
            <InputAdornment>
              <IconButton edge="start">
                <SearchIcon style={{ color: "#FFFFFF" }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};
