import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

const useStylesReddit = makeStyles((theme) => ({
  root: {
    width: "49.2%",
    marginBottom: "10px",
    borderRadius: "10px",
    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "#ffffff",
    },
  },
}));
export const InputGeneral = ({
  nameLabel,
  valueEnd,
  inputType,
  valueForm,
  functionForm,
}) => {
  const classes = useStylesReddit();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    val: valueForm,
  });

  const { val } = values;
  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });

    dispatch(functionForm(target.value));
  };

  return (
    <span>
      <TextField
        label={nameLabel}
        id="outlined-start-adornment"
        required
        className={classes.root}
        InputProps={{
          inputProps: { min: 0 },
          shrink: "true",
          endAdornment: (
            <InputAdornment position="end">
              {val !== "" && (
                <span style={{ color: "#FFFFFF" }}>{valueEnd}</span>
              )}
            </InputAdornment>
          ),
        }}
        variant="outlined"
        type={inputType}
        name="val"
        value={val}
        rowsmin="1"
        onChange={handleInputChange}
        component="div"
      />
    </span>
  );
};
