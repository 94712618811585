import { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { DashboardRoutes } from "./DashboardRoutes";
import { PrivateRouter } from "./PrivateRouter";
import { PublicRouter } from "./PublicRouter";
import { startLogout, startRefresToken } from "../auth/authAction";
import { useDispatch, useSelector } from "react-redux";
import { AuthRouter } from "./AuthRouter";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const tokenExpire = localStorage.getItem("token-init-date");

  useEffect(() => {
    dispatch(startRefresToken());
  }, [dispatch]);

  if (tokenExpire) {
    if (tokenExpire < 60) dispatch(startLogout());
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRouter
            exact
            path="/auth/login"
            component={AuthRouter}
            isAuthenticated={!!user}
          />{" "}
          <PrivateRouter
            exact
            path="/"
            component={DashboardRoutes}
            isAuthenticated={!!user}
          />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </Router>
  );
};
