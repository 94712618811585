import React, { useState } from "react";
import { InputSearch } from "../inputSearch/InputSearch";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import {
  handleError,
  messageModal,
  URLAdvertisingSpaces,
} from "../../helpers/httpMethod";
import { ButtonFilterPrice } from "../buttonFilterPrice/ButtomFilterPrice";
import { ButtonAllFilter } from "../buttonAllFilter/ButtonAllFilter";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ButtonFilterAvaibility } from "../buttonFilterAvaibility/ButtonFilterAvaibility";
import { InputFilterType } from "../inputFilterType/InputFilterType";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#0A1F3B",
    borderBottom: "2px solid #253A60",
    paddingBottom: "0.3em",
  },
  bg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  conten: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "1em",
  },
  filter: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "15px",
    marginBottom: "1em",
    paddingLeft: "1em",
  },
}));

export const ContainerFilters = ({
  setInfoSpace,
  typeSpace,
  setOrderPrice,
}) => {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [values, setValues] = useState({
    nameSpace: "",
    min: "",
    max: "",
  });

  const { nameSpace, min, max } = values;
  const [isAvailability, setIsAvailability] = useState(1);
  const getSpaces = (enpoint) => {
    const access_token = localStorage.getItem("token");

    axios
      .get(enpoint, {
        headers: {
          Authorization: `token ${access_token}`,
        },
      })
      .then((res) => {
        setInfoSpace(res.data);
        setOrderPrice(null);
        if (res.data.data.length === 0) messageModal("No encontrado");

        return res.data;
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleInputName = ({ target }) => {
    const nameSearch = target.value;
    setValues({
      nameSpace: nameSearch,
      min: "",
      max: "",
    });

    const enpoint = `${URLAdvertisingSpaces}?search=${nameSearch}`;
    getSpaces(enpoint);
  };

  const handleInputMinPrice = ({ target }) => {
    const minPrice = target.value;
    setValues({
      nameSpace,
      min: minPrice,
      max,
    });
    const enpoint = `${URLAdvertisingSpaces}?min=${minPrice}&max=${max}`;
    getSpaces(enpoint);
  };

  const handleInputMaxPrice = ({ target }) => {
    const maxPrice = target.value;
    setValues({
      nameSpace,
      min,
      max: maxPrice,
    });
    const enpoint = `${URLAdvertisingSpaces}?min=${min}&max=${maxPrice}`;
    getSpaces(enpoint);
  };

  const handleSubmitAll = () => {
    getSpaces(URLAdvertisingSpaces);
  };

  const handleSubmitAvaibility = () => {
    const newAvailable = isAvailability === 1 ? 0 : 1;
    setIsAvailability(newAvailable);
    const enpoint = `${URLAdvertisingSpaces}?is_availability=${newAvailable}`;
    getSpaces(enpoint);
  };

  const handleClickTypes = (id) => {
    const enpoint = `${URLAdvertisingSpaces}?advertising_space_type_id=${id}`;
    getSpaces(enpoint);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
      >
        <Grid item xs={12} sm={4}>
          <InputSearch
            nameSpace={nameSpace}
            handleInputName={handleInputName}
          />
        </Grid>
        {isTabletOrMobile && (
          <Grid item xs={12}>
            <div className={classes.filter}>Filtrar por</div>
          </Grid>
        )}
        {isTabletOrMobile ? (
          <Grid item xs={3} sm={2} className={classes.conten}>
            <ButtonAllFilter handleSubmitAll={handleSubmitAll} />
          </Grid>
        ) : (
          <Grid item xs={3} sm={2} className={classes.bg}>
            <ButtonAllFilter handleSubmitAll={handleSubmitAll} />
          </Grid>
        )}
        <Grid item xs={3} sm={2} className={classes.bg}>
          <ButtonFilterPrice
            min={min}
            max={max}
            handleInputMinPrice={handleInputMinPrice}
            handleInputMaxPrice={handleInputMaxPrice}
          />
        </Grid>
        <Grid item xs={3} sm={2} className={classes.bg}>
          <InputFilterType
            typeSpace={typeSpace}
            handleClickTypes={handleClickTypes}
          />
        </Grid>
        <Grid item xs={3} sm={2} className={classes.bg}>
          <ButtonFilterAvaibility
            handleSubmitAvaibility={handleSubmitAvaibility}
            isAvailability={isAvailability}
          />
        </Grid>
      </Grid>
    </div>
  );
};
