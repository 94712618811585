import { types } from "../types/types";

const INITIAL_STATE = {
  typeOfSpace: "Selecciona una opción",
  nameSpace: "",

  minimalPrice: "0",
  maximumPrice: "0",

  minimumDuration: "0",
  maximumDuration: "0",

  height: "0",
  base: "0",
  typeOfMaterial: "Selecciona tipo de material",

  imgs: [null, null, null, null],
  picture: Array.of(null, null, null, null),

  availability: {
    isAvailability: null,
    date: new Date(),
  },

  sponsorType: [],

  optionsTypeSpace: [],
  optionsMaterial: [],
};

export const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.reset:
      return (state = INITIAL_STATE);

    case types.typeSpace:
      return {
        ...state,
        typeOfSpace: action.payload,
      };

    case types.nameNewSpace:
      return {
        ...state,
        nameSpace: action.payload,
      };

    case types.monthlyFeeMin:
      return {
        ...state,
        minimalPrice: action.payload,
      };

    case types.monthlyFeeMax:
      return {
        ...state,
        maximumPrice: action.payload,
      };

    case types.contractTimeMin:
      return {
        ...state,
        minimumDuration: action.payload,
      };

    case types.contractTimeMax:
      return {
        ...state,
        maximumDuration: action.payload,
      };

    case types.spaceHeight:
      return {
        ...state,
        height: action.payload,
      };
    case types.spaceBase:
      return {
        ...state,
        base: action.payload,
      };

    case types.typeMaterial:
      return {
        ...state,
        typeOfMaterial: action.payload,
      };

    case types.imgGallery:
      return {
        ...state,
        imgs: action.payload,
      };

    case types.pictureGallery:
      return {
        ...state,
        picture: action.payload,
      };

    case types.availability:
      return {
        ...state,
        availability: action.payload,
      };

    case types.sponsorType:
      return {
        ...state,
        sponsorType: action.payload,
      };

    case types.addSponsorType:
      return {
        ...state,
        sponsorType: action.payload,
      };

    case types.optionsSpaceType:
      return {
        ...state,
        optionsTypeSpace: action.payload,
      };

    case types.optionsMaterialType:
      return {
        ...state,
        optionsMaterial: action.payload,
      };
    default:
      return state;
  }
};
