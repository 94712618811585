import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ContainerRegister } from "../components/containerRegister/ContainerRegister";
import { ContainerSponsorship } from "../components/containerSponsorship/ContainerSponsorship";

export const DashboardRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={ContainerSponsorship} />
        <Route exact path="/patrocinios" component={ContainerRegister} />
      </Switch>
    </Router>
  );
};
