import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import "./LowerNavbar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const LowerNavbar = () => {
  const classes = useStyles();
  //position="absolute"
  return (
    <div className="nav-lower">
      <div className={classes.root}>
        <AppBar position="relative" color="primary" className="container-btn">
          <Button
            type="submit"
            className="btn-crear"
            color="secondary"
            variant="contained"
            disableElevation
          >
            Crear
          </Button>
        </AppBar>
      </div>
    </div>
  );
};
