import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sponsorTypes } from "../../action/formActions";

const GreenBox = withStyles({
  root: {
    color: "#9CAFC3",
    "&$checked": {
      color: "#64FF6C",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));
export const SponsorType = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sponsorType = useSelector((state) => state.leagues.sponsorType);

  const [allChecked, setAllChecked] = useState(true);
  const [isChecked, setIsChecked] = useState(sponsorType);

  const handleInput = (i, e) => {
    if (e.target.value === "Todos") {
      setAllChecked(false);
    }

    if (!isChecked[i].checked) {
      const newArrayTrue = isChecked.map((n, ind) =>
        ind === i ? { id: n.id, name: n.name, checked: true } : n
      );
      dispatch(sponsorTypes(newArrayTrue));
      setIsChecked(newArrayTrue);
    }

    if (isChecked[i].checked) {
      const newArrayFalse = isChecked.map((n, ind) =>
        ind === i ? { id: n.id, name: n.name, checked: false } : n
      );
      dispatch(sponsorTypes(newArrayFalse));
      setIsChecked(newArrayFalse);
    }
  };

  useEffect(() => {
    if (allChecked === false) {
      const isCheckedAll = () => {
        if (isChecked[isChecked.length - 1].checked) {
          const newArrayAllTrue = isChecked.map(
            (n) => (n = { id: n.id, name: n.name, checked: true })
          );
          dispatch(sponsorTypes(newArrayAllTrue));
          setIsChecked(newArrayAllTrue);
        } else {
          const newArrayAllFalse = isChecked.map(
            (n) => (n = { id: n.id, name: n.name, checked: false })
          );
          dispatch(sponsorTypes(newArrayAllFalse));
          setIsChecked(newArrayAllFalse);
        }
      };
      isCheckedAll();
      setAllChecked(true);
    }
  }, [allChecked, dispatch, isChecked]);

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup aria-label="position" row style={{ width: "100%" }}>
        {allChecked &&
          isChecked.map((l, i) => (
            <FormControlLabel
              style={{ width: "45%" }}
              key={l.name}
              value={l.name}
              control={<GreenBox />}
              label={l.name}
              checked={isChecked[i].checked}
              labelPlacement="end"
              onChange={(e) => handleInput(i, e)}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};
