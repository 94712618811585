import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { capitalize } from "@material-ui/core";
import "./CardSpaceLeague.css";
const useStyles = makeStyles({
  // root: {
  //   maxWidth: 405,
  //   minWidth: 405,
  //   background: "#0A1F3B",
  //   margin: "10px",
  // },

  title: {
    color: "#64FF6C",
  },
  // image: {
  //   maxWidth: 385,
  //   borderRadius: "20px",
  //   padding: "10px 10px 0px 10px",
  // },
  icon: {
    color: "#FFFFFF",
    fontSize: "12px",
    marginRight: "10px",
  },
  alaing: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    marginBottom: "5px",
  },
  price: {
    color: "#9CAFC3",
  },
});

export const CardSpaceLeague = ({ data }) => {
  const classes = useStyles();
  const { name, minimal_price, maximum_price, is_availability, media } = data;

  const nameCapitalize = capitalize(name);

  return (
    <Card className="root" style={{ background: "#0a1f3b" }}>
      <CardActionArea>
        {media.length > 0 ? (
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="180"
            image={media[0].url}
            title="Contemplative Reptile"
            className="image"
          />
        ) : (
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="180"
            image={"/img/imgNO.png"}
            title="Contemplative Reptile"
            className="image"
          />
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {nameCapitalize}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={classes.alaing}>
              <FiberManualRecordIcon className={classes.icon} />{" "}
              {is_availability}
            </div>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={classes.price}>
              Precio min: ${minimal_price} MXN
            </div>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={classes.price}>
              Precio deseado: ${maximum_price} MXN
            </div>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
