import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  URLSpaceType,
  URLSponsorType,
  URLSpaceMaterial,
  URLAdvertisingSpaces,
  handleError,
  messageModal,
} from "../../helpers/httpMethod";
import { LowerNavbar } from "../lowerNavbar/LowerNavbar";
import { StepperTypeForm } from "../stepperTypeForm/StepperTypeForm";
import { TopNavbar } from "../topNavbar/TopNavbar";
import {
  addSponsorType,
  optionsSpaceType,
  optionsMaterialType,
} from "../../action/formActions";
import "./ContainerRegister.css";

export const ContainerRegister = ({ history }) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const [listSponsorType, setListSponsorType] = useState([]);
  const [listSpaceType, setListSpaceType] = useState(null);
  const [listSpaceMaterial, setListSpaceMaterial] = useState(null);
  const [leaguesValue, setLeaguesValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [completed, setCompleted] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });

  const getSponsorType = (enpoint, access_token) => {
    axios
      .get(enpoint, {
        headers: {
          Authorization: `token ${access_token}`,
        },
      })
      .then((res) => {
        setListSponsorType(res.data.data);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getSpaceType = (enpoint, access_token) => {
    axios
      .get(enpoint, {
        headers: {
          Authorization: `token ${access_token}`,
        },
      })
      .then((res) => {
        setListSpaceType(res.data.data);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSpaceMaterial = (enpoint, access_token) => {
    axios
      .get(enpoint, {
        headers: {
          Authorization: `token ${access_token}`,
        },
      })
      .then((res) => {
        setListSpaceMaterial(res.data.data);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendAdvertisingSpaces = async (access_token, values) => {
    var data = new FormData();
    data.append("base", values.base);
    data.append("height", values.height);
    data.append("isAvailability", values.isAvailability);
    data.append("maximumDuration", values.maximumDuration);
    data.append("maximumPrice", values.maximumPrice);
    data.append("minimalPrice", values.minimalPrice);
    data.append("minimumDuration", values.minimumDuration);
    data.append("name", values.name);
    data.append("typeOfMaterial", values.typeOfMaterial);
    data.append("typeOfSpace", values.typeOfSpace);
    data.append("date", values.date);

    values.images.forEach((image_file) => {
      data.append("images[]", image_file);
    });

    values.sponsorType.forEach((sponsor) => {
      data.append("sponsorType[]", sponsor);
    });

    const obj = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${access_token}`,
      }),
      body: data,
    };
    try {
      const res = await fetch(URLAdvertisingSpaces, obj);
      const dataRes = await res.json();
      return dataRes;
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getSponsorType(URLSponsorType, token);
    getSpaceType(URLSpaceType, token);
    getSpaceMaterial(URLSpaceMaterial, token);
  }, [token]);

  const addcheckedToArray = (arrSponsorType) => {
    const listSponsor = arrSponsorType.map((elem) => ({
      ...elem,
      checked: false,
    }));

    listSponsor.push({
      id: null,
      name: "Todos",
      checked: false,
    });

    return listSponsor;
  };

  useEffect(() => {
    if (listSponsorType.length > 0) {
      const listSponsor = addcheckedToArray(listSponsorType);
      dispatch(addSponsorType(listSponsor));
    }
  }, [listSponsorType, dispatch]);

  useEffect(() => {
    if (listSpaceType) dispatch(optionsSpaceType(listSpaceType));
    if (listSpaceMaterial) dispatch(optionsMaterialType(listSpaceMaterial));
  }, [listSpaceType, listSpaceMaterial, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formCompleted = Object.keys(completed)
      .map((key) => (key !== "" ? completed[key] : null))
      .includes(false);

    if (!formCompleted) {
      setIsSubmit(true);
    } else {
      messageModal("Todos los campos son requeridos");
    }
  };

  const onSubmit = useCallback(async (data) => {
    if (data) {
      const res = await sendAdvertisingSpaces(token, data);
      messageModal(res.message);
      if (res.error === false) {
        history.replace("/");
      }
    }
    // eslint-disable-next-line
  }, [history, leaguesValue, token]);

  return (
    <div className="container-register">
      <TopNavbar />
      <form action="" onSubmit={handleSubmit}>
        <StepperTypeForm
          completed={completed}
          setCompleted={setCompleted}
          setLeaguesValue={setLeaguesValue}
          isSubmit={isSubmit}
          onSubmit={onSubmit}
        />
        <LowerNavbar />
      </form>
    </div>
  );
};
