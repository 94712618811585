import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

const useStylesReddit = makeStyles((theme) => ({
  root: {
    width: "48%",
    "& label": {
      color: "#9cafc3",
    },
    "& label.Mui-focused": {
      color: "#9cafc3",
      backgroundColor: "#0C1E3B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9cafc3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9cafc3",
      },
      "&:hover fieldset": {
        borderColor: "#64FF6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9cafc3",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
}));
export const InputPrice = ({ nameLabel, valueForm, functionForm }) => {
  const dispatch = useDispatch();

  const classes = useStylesReddit();

  const [values, setValues] = useState({
    price: valueForm,
  });

  const { price } = values;
  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
    dispatch(functionForm(target.value));
  };
  return (
    <span>
      <TextField
        label={nameLabel}
        id="outlined-start-adornment"
        required
        className={classes.root}
        InputProps={{
          inputProps: { min: 0 },
          shrink: "true",
          startAdornment: (
            <InputAdornment position="start">
              <span style={{ color: "#FFFFFF" }}>$</span>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        type="number"
        name="price"
        value={price}
        onChange={handleInputChange}
        component="div"
      />
    </span>
  );
};
