import React, { useEffect, useState } from "react";
import { TopNavbarSpace } from "../topNavbarSpace/TopNavbarSpace";
import {
  handleError,
  URLSpaceType,
  URLAdvertisingSpaces,
} from "../../helpers/httpMethod";
import axios from "axios";
import { ContainerCardSpace } from "../containerCardSpace/ContainerCardSpace";
import { Spinner } from "../spinner/Spinner";
import { ContainerFilters } from "../containerFilters/ContainerFilters";
import { NavbarBottom } from "../navbarBottom/NavbarBottom";
import { TotalResult } from "../totalResult/TotalResult";

export const ContainerSponsorship = () => {
  const [infoSpace, setInfoSpace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [typeSpace, setTypeSpace] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderPrice, setOrderPrice] = useState(null);

  const getTypesSpaces = (enpoint) => {
    const access_token = localStorage.getItem("token");
    axios
      .get(enpoint, {
        headers: {
          Authorization: `token ${access_token}`,
        },
      })
      .then((res) => {
        if (res.data.error === false) {
          setTypeSpace(res.data);
          setOrderPrice(null);
          setLoading2(false);
        }

        return res.data;
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    const getAdvertisingSpaces = (enpoint) => {
      const access_token = localStorage.getItem("token");
      axios
        .get(enpoint, {
          headers: {
            Authorization: `token ${access_token}`,
          },
        })
        .then((res) => {
          if (res.data.error === false) {
            setInfoSpace(res.data);
            setOrderPrice(null);
            setLoading(false);
          }
          return res.data;
        })
        .catch((error) => {
          handleError(error);
        });
    };
    getAdvertisingSpaces(URLAdvertisingSpaces);
    return () => {
      setLoading(null);
    };
  }, []);

  useEffect(() => {
    getTypesSpaces(URLSpaceType);
    return () => {
      setLoading2(null);
    };
  }, []);

  useEffect(() => {
    if (order) {
      setLoading(true);
      const orderCards = () => {
        if (order === "Precio más bajo") {
          const dataMinimalPrice = infoSpace.data.sort(
            (a, b) => a.minimal_price - b.minimal_price
          );
          setOrderPrice(dataMinimalPrice);
          setLoading(false);
          setOrder(null);
        }
        if (order === "Precio más alto") {
          const dataMaximumPrice = infoSpace.data.sort(
            (a, b) => b.minimal_price - a.minimal_price
          );
          setOrderPrice(dataMaximumPrice);
          setLoading(false);
          setOrder(null);
        }
      };
      orderCards();
    }
  }, [order, infoSpace]);

  return (
    <div style={{ background: "#0A1F3B", minHeight: "100vh" }}>
      {loading && <Spinner />}
      {!loading && !loading2 && (
        <div>
          <TopNavbarSpace />
          <ContainerFilters
            setInfoSpace={setInfoSpace}
            typeSpace={typeSpace}
            setOrderPrice={setOrderPrice}
          />
          <TotalResult
            infoSpace={infoSpace.data}
            setOrder={setOrder}
            orderPrice={orderPrice}
          />
          {orderPrice ? (
            <ContainerCardSpace infoSpace={orderPrice} />
          ) : (
            <ContainerCardSpace infoSpace={infoSpace.data} />
          )}

          <NavbarBottom />
        </div>
      )}
    </div>
  );
};
