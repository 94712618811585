import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { ButtonIcon } from "../buttonIcon/ButtonIcon";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: { background: "#0A1F3B", borderBottom: "2px solid #253A60" },
  title: { fontWeight: "bold" },
  contain: {
    paddingLeft: "1em",
  },
}));
export const TopNavbarSpace = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.contain}
        >
          <Typography
            component={"h6"}
            variant="h6"
            color="inherit"
            className={classes.title}
          >
            Mis espacios de patrocinio
          </Typography>

          <ButtonIcon />
        </Grid>
      </AppBar>
    </div>
  );
};
