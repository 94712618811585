import * as yup from 'yup';

const formSchema = yup.object().shape({
    country: yup.string().required('Campo requerido'),
    address: yup.string().required('Campo requerido'),
    city: yup.string().required('Campo requerido'),
    state: yup.string().required('Campo requerido'),
    lat: yup.string(),
    lng: yup.string(),
    name: yup.string().required('Campo requerido'),
    description: yup.string(),
    owner: yup.object().shape({
        name: yup.string().required('Campo requerido'),
        lastname: yup.string().required('Campo requerido'),
        email: yup.string().required('Campo requerido'),
        password: yup.string().min(8, 'Contraseña de minimo 8 caracteres').max(255, 'Contraseña máximo 255 caracteres').required('Campo requerido'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
        phone: yup.string().required('Campo requerido'),
    }),
    social: yup.object().shape({
        facebook: yup.string(),
        facebookUrl: yup.string(),
        twitter: yup.string(),
        twitterUrl: yup.string(),
        instagram: yup.string(),
        instagramUrl: yup.string(),
    }),
});

export default formSchema;