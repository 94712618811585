import React, { useEffect, useState, memo } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { getCity, getCountry, getState } from "../../helpers/utils";
import { GoogleMapsAPI } from "../../client-config";

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

export const Map = memo(({ latitude, long, onPlaceChanged }) => {
  //const classes = useStyles();
  const [stateAddress, setStateAddress] = useState({
    country: "",
    address: "",
    city: "",
    state: "",
    zoom: 15,
    height: 400,
    mapPosition: {
      lat: latitude,
      lng: long,
    },
    markerPosition: {
      lat: latitude,
      lng: long,
    },
  });

  const {
    address,
    zoom,
    height,
    mapPosition,
    markerPosition,
  } = stateAddress;

  useEffect(() => {
    Geocode.fromLatLng(latitude, long).then(
      (response) => {
        const addressGeo = response.results[0].formatted_address;
        const addressArr = response.results[0].address_components;
        const cityGeo = getCity(addressArr);
        const stateGeo = getState(addressArr);
        const countryGeo = getCountry(addressArr);
        setStateAddress((s) => ({
          ...s,
          country: countryGeo,
          address: addressGeo ? addressGeo : "",
          city: cityGeo ? cityGeo : "",
          state: stateGeo ? stateGeo : "",
        }));
        onPlaceChanged({
          country: countryGeo,
          address: addressGeo ? addressGeo : "",
          city: cityGeo ? cityGeo : "",
          state: stateGeo ? stateGeo : "",
          lat: latitude,
          lng: long
        })
      },
      (error) => {
        console.error(error);
      }
    );
    // eslint-disable-next-line
  }, [latitude, long]);

  const onInfoWindowClose = (event) => {};

  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArr = response.results[0].address_components;
        const city = getCity(addressArr);
        const state = getState(addressArr);
        const country = getCountry(addressArr);
        // testC.current.value = country;
        setStateAddress({
          ...stateAddress,
          country: country ? country : "",
          address: address ? address : "",
          city: city ? city : "",
          state: state ? state : "",
          lat: newLat,
          lng: newLng,
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
        onPlaceChanged({
          country: country ? country : "",
          address: address ? address : "",
          city: city ? city : "",
          state: state ? state : "",
          lat: newLat,
          lng: newLng,
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        })
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onPlaceSelected = (place) => {
    if (
      place.formatted_address !== undefined &&
      place.address_components !== undefined
    ) {
      const address = place.formatted_address;
      const addressArr = place.address_components;
      const city = getCity(addressArr);
      const state = getState(addressArr);
      const country = getCountry(addressArr);
      const latValue = place.geometry.location.lat();
      const lngValue = place.geometry.location.lng();

      // Set these values in the state.
      setStateAddress({
        ...stateAddress,
        country: country ? country : "",
        address: address ? address : "",
        city: city ? city : "",
        state: state ? state : "",
        lat: latValue ? latValue : "",
        lng: lngValue ? lngValue : "",
        markerPosition: {
          lat: latValue ? latValue : "",
          lng: lngValue ? lngValue : "",
        },
        mapPosition: {
          lat: latValue ? latValue : "",
          lng: lngValue ? lngValue : "",
        },
      });

      onPlaceChanged({
        country: country ? country : "",
        address: address ? address : "",
        city: city ? city : "",
        state: state ? state : "",
        lat: latValue ? latValue : "",
        lng: lngValue ? lngValue : "",
        markerPosition: {
          lat: latValue ? latValue : "",
          lng: lngValue ? lngValue : "",
        },
        mapPosition: {
          lat: latValue ? latValue : "",
          lng: lngValue ? lngValue : "",
        },
      })
    }
  };

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={{
          lat: mapPosition.lat,
          lng: mapPosition.lng,
        }}
      >
        <Marker
          google={props.google}
          name={"Dolores park"}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{
            lat: markerPosition.lat,
            lng: markerPosition.lng,
          }}
        />
        <InfoWindow
          onClose={onInfoWindowClose}
          position={{
            lat: markerPosition.lat + 0.0018,
            lng: markerPosition.lng,
          }}
        >
          <div>
            <span style={{ padding: 0, margin: 0 }}>{address}</span>
          </div>
        </InfoWindow>
        <Marker />

        <Autocomplete
          apiKey={GoogleMapsAPI}
          style={{
            width: "100%",
            height: "40px",
            paddingLeft: "16px",
            marginTop: "2px",
            marginBottom: "2em",
          }}
          onPlaceSelected={onPlaceSelected}
          options={{
            types: ["establishment", "geocode"],
          }}
        />
      </GoogleMap>
    ))
  );

  return (
    <>
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            style={{ height: height, width: "100%", marginBottom: "3.5em" }}
          />
        }
        mapElement={<div style={{ height: `100%` }} />}
      />
    </>
  );
});
