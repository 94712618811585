import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Login } from "../components/login/Login";
import { ContainerSingup } from "../components/containerSingup/ContainerSingup";

export const AuthRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/singup" component={ContainerSingup} />
        <Redirect to="/auth/login" />
      </Switch>
    </Router>
  );
};
