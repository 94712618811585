import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import AddIcon from "@material-ui/icons/Add";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { imgGallery, pictureGalleryFile } from "../../action/formActions";
import "./PhotoModal.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "#FFFFFF",
    backgroundColor: "#0c1e3b94",
  },
  paper: {
    textAlign: "center",
    width: "80%",
    height: "35%",
    fontSize: "16px",
    backgroundColor: "#253A60",
    border: "#253a60",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
  },
  input: {
    display: "none",
  },
}));

export const PhotoModal = ({ isCameraIcon, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const picture = useSelector((state) => state.leagues.picture);
  const imgs = useSelector((state) => state.leagues.imgs);
  const [open, setOpen] = useState(false);

  //funtion open modal
  const handleOpen = () => {
    setOpen(true);
  };
  // funtion close modal
  const handleClose = () => {
    setOpen(false);
  };

  const onChangePicture = ({ target }) => {
    if (target.files[0]) {
      const newPicture = picture.map((pic, idx) =>
        idx === index ? target.files[0] : pic
      );
      dispatch(pictureGalleryFile(newPicture));
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const newImg = imgs.map((img, idx) =>
          idx === index ? reader.result : img
        );
        dispatch(imgGallery(newImg));
      });
      reader.readAsDataURL(target.files[0]);

      handleClose(); // funtion close modal
    }
  };

  return (
    <Grid container item xs={6} className="container-photo">
      <button
        type="button"
        className="container-btn-photo"
        onClick={handleOpen}
      >
        {imgs[index] === null ? (
          <div>
            {isCameraIcon ? (
              <>
                <div>
                  <PhotoCameraIcon className="icon-photo" />
                </div>
                Tomar foto principal o{" "}
                <div className="text-img">carga una imagen</div>{" "}
              </>
            ) : (
              <AddIcon className="icon-photo" />
            )}
          </div>
        ) : (
          <div className="box">
            <img src={imgs[index]} alt="imagen" />
          </div>
        )}
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h3>AGREGA UNA FOTO</h3>
            <div style={{ width: "100%" }}>
              <Box display="flex">
                <Box p={1} width="50%">
                  <input
                    accept="image/*"
                    capture="camera"
                    id="icon-button-photo"
                    className={classes.input}
                    type="file"
                    onChange={onChangePicture}
                  />
                  <label htmlFor="icon-button-photo">
                    <img src="/img/camera.png" alt="camara" />
                    <Box color="#CFFF00">
                      <span> Tomar Foto</span>
                    </Box>
                  </label>
                </Box>

                <Box p={1} width="50%" flexShrink={1}>
                  <input
                    type="file"
                    accept="image/*"
                    id="icon-button-gallery"
                    className={classes.input}
                    onChange={onChangePicture}
                  />
                  <label htmlFor="icon-button-gallery">
                    <img src="/img/gallery.png" alt="galeria" />
                    <Box flexShrink={1} color="#CFFF00">
                      <span>Galería</span>
                    </Box>
                  </label>
                </Box>
              </Box>
            </div>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};
