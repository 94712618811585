import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import { resetState } from "../../action/formActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    background: "#64FF6C",
    marginRight: "3em",
  },
}));

export const NavbarBottom = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(resetState());
    history.push("/patrocinios");
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <div>
            <IconButton edge="start" color="inherit" aria-label="open drawer">
              <MenuIcon />
            </IconButton>
          </div>

          <div className={classes.grow} />
          <div>
            <IconButton color="inherit">
              <img src="/img/sign.png" alt="sign" />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.location.replace('https://pitzv2.firebaseapp.com/teams')}>
              {" "}
              <span className="material-icons" style={{ color: "#9CAFC3" }}>
                sports_soccer
              </span>
            </IconButton>
            <IconButton color="inherit" onClick={() => window.location.replace('https://pitzv2.firebaseapp.com/tournaments')}>
              <span className="material-icons" style={{ color: "#9CAFC3" }}>
                emoji_events
              </span>
            </IconButton>
            <IconButton color="inherit" onClick={() => window.location.replace('https://pitzv2.firebaseapp.com/notifications')}>
              {" "}
              <span className="material-icons" style={{ color: "#9CAFC3" }}>
                notifications
              </span>
            </IconButton>
          </div>

          <div
            onClick={() => handleClick()}
            style={{ background: "red", marginRight: "4.3em" }}
          >
            <Fab
              color="inherit"
              edge="end"
              aria-label="add"
              className={classes.fabButton}
            >
              <AddIcon style={{ color: "#FFFFFF" }} />
            </Fab>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
