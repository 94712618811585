import { types } from "../types/types";

export const typeSpace = (typeOfSpace) => ({
  type: types.typeSpace,
  payload: typeOfSpace,
});

export const optionsSpaceType = (optionsSpace) => ({
  type: types.optionsSpaceType,
  payload: optionsSpace,
});

export const monthlyFeeMin = (minimalPrice) => ({
  type: types.monthlyFeeMin,
  payload: minimalPrice,
});

export const monthlyFeeMax = (maximumPrice) => ({
  type: types.monthlyFeeMax,
  payload: maximumPrice,
});

export const contractTimeMin = (minimumDuration) => ({
  type: types.contractTimeMin,
  payload: minimumDuration,
});

export const contractTimeMax = (maximumDuration) => ({
  type: types.contractTimeMax,
  payload: maximumDuration,
});

export const spaceHeight = (height) => ({
  type: types.spaceHeight,
  payload: height,
});

export const spaceBase = (base) => ({
  type: types.spaceBase,
  payload: base,
});

export const typeMaterial = (typeOfMaterial) => ({
  type: types.typeMaterial,
  payload: typeOfMaterial,
});

export const optionsMaterialType = (typeMaterial) => ({
  type: types.optionsMaterialType,
  payload: typeMaterial,
});

export const imgGallery = (img) => ({
  type: types.imgGallery,
  payload: img,
});

export const pictureGalleryFile = (picture) => ({
  type: types.pictureGallery,
  payload: picture,
});

export const sponsorTypes = (arraySponsorTypes) => ({
  type: types.sponsorType,
  payload: arraySponsorTypes,
});

export const addSponsorType = (addArray) => ({
  type: types.addSponsorType,
  payload: addArray,
});

export const availabilityDate = (dateAvailability) => ({
  type: types.availability,
  payload: dateAvailability,
});

export const nameNewSpace = (nameSpace) => ({
  type: types.nameNewSpace,
  payload: nameSpace,
});

export const resetState = () => ({
  type: types.reset,
});
