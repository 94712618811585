import { authenticationUser } from "../types/authenticationUser";

const INiTIAL_STATE = {
  checking: true,
};

export const authReducer = (state = INiTIAL_STATE, action) => {
  switch (action.type) {
    case authenticationUser.authLogin:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };

    case authenticationUser.authCheckingFinish:
      return {
        ...state,
        checking: true,
      };

    case authenticationUser.authLogout:
      return {
        checking: true,
      };

    default:
      return state;
  }
};
