import { Box } from "@material-ui/core";
import React from "react";
import { CardSpaceLeague } from "../cardSpaceLeague/CardSpaceLeague";

export const ContainerCardSpace = ({ infoSpace }) => {
  return (
    <div style={{ width: "100%", paddingBottom: "3.8em" }}>
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
        {infoSpace.map((elem) => (
          <div key={elem.id}>
            <CardSpaceLeague data={elem} />
          </div>
        ))}
      </Box>
    </div>
  );
};
