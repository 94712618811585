import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { startLogout } from "../../auth/authAction";

export const ButtonIcon = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(startLogout());
  };
  return (
    <Link to="/patrocinios">
      <div onClick={() => handleLogout()}>
        <IconButton style={{ color: "#FFFFFF" }} aria-label="salir">
          <ExitToAppIcon />
        </IconButton>
      </div>
    </Link>
  );
};
