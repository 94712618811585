import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    maxWidth: "100%",

    "& > *": {
      background: "#253A60",
      color: "#FFFFFF",
      borderRadius: "10px",

      "&:hover": {
        background: "linear-gradient(to right bottom, #D4F123, #37F8A2)",
        color: "#253A60",
      },
    },
  },
  btn: {
    minWidth: "91%",
    maxWidth: "91%",
    height: "8.5vh",
  },
}));

export const ButtonAllFilter = ({ handleSubmitAll }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmitAll}
        className={classes.btn}
      >
        Todos
      </Button>
    </div>
  );
};
