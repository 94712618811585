import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { StepButton } from "@material-ui/core";
import { getStepContent } from "./contentStepper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import RemoveIcon from "@material-ui/icons/Remove";
import "./StepperTypeForm.css";
import moment from "moment";

const QontoConnector = withStyles({
  active: {
    "& $line": {
      borderColor: "#9CAFC3",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#64FF6C",
    },
  },
  line: {
    borderColor: "#64FF6C",
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "static",
  },
  style: {
    background: "#0c1e3b",
    color: "#FFFFFF",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "¿Que tipo de espacio es?",
    "Tarifa mensual",
    "Tiempo de contrato",
    "Características del espacio",
    "Galería de fotos",
    "Disponibilidad",
    "Tipo de patrocinador permitido",
  ];
}
export const StepperTypeForm = ({
  completed,
  setCompleted,
  isSubmit,
  setLeaguesValue,
  onSubmit
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(null);

  const steps = getSteps();

  const {
    optionsTypeSpace,
    optionsMaterial,
    nameSpace,
    typeOfSpace,
    minimalPrice,
    maximumPrice,
    minimumDuration,
    maximumDuration,
    height,
    base,
    typeOfMaterial,
    picture,
    imgs,
    availability,
    sponsorType,
  } = useSelector((state) => state.leagues);

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleComplete = useCallback((stepCompleted) => {
    if (activeStep === null) return;
    const newCompleted = completed;
    newCompleted[activeStep] = stepCompleted;
    setCompleted(newCompleted);
  }, [activeStep, completed, setCompleted]);

  const valiValue = (val1, val2) => {
    if (Number(val1) > 0 && Number(val2) > 0) return true;
    return false;
  };

  const addIdOptionType = (arrayTypes, optionElegida) => {
    const result = arrayTypes.filter((elem) => elem.name === optionElegida);
    return result[0].id;
  };

  const addIdImages = (arrayimages) => {
    const result = arrayimages.filter((img) => img !== null);
    return result;
  };

  const addIdSponsorType = (arrSponsor) => {
    const arrChecked = arrSponsor.filter(
      (elem) => elem.checked === true && elem.name !== "Todos"
    );
    return arrChecked.map((elem) => elem.id);
  };

  const addAvailability = (optionAvailability) => {
    return optionAvailability === "Si" ? 1 : 0;
  };

  useEffect(() => {
    const addDate = (date) => {
      return date === "" ? "" : moment(availability.date).format("YYYY-MM-DD");
    };
    if (isSubmit) {
      setLeaguesValue({
        base: base,
        height: height,
        isAvailability: addAvailability(availability.isAvailability),
        maximumDuration: maximumDuration,
        maximumPrice: maximumPrice,
        minimalPrice: minimalPrice,
        minimumDuration: minimumDuration,
        name: nameSpace,
        typeOfMaterial: addIdOptionType(optionsMaterial, typeOfMaterial),
        typeOfSpace: addIdOptionType(optionsTypeSpace, typeOfSpace),
        images: addIdImages(picture),
        sponsorType: addIdSponsorType(sponsorType),
        date: addDate(availability.date),
      });

      onSubmit({
        base: base,
        height: height,
        isAvailability: addAvailability(availability.isAvailability),
        maximumDuration: maximumDuration,
        maximumPrice: maximumPrice,
        minimalPrice: minimalPrice,
        minimumDuration: minimumDuration,
        name: nameSpace,
        typeOfMaterial: addIdOptionType(optionsMaterial, typeOfMaterial),
        typeOfSpace: addIdOptionType(optionsTypeSpace, typeOfSpace),
        images: addIdImages(picture),
        sponsorType: addIdSponsorType(sponsorType),
        date: addDate(availability.date),
      })
    }
    // eslint-disable-next-line
  }, [isSubmit]);

  useEffect(() => {
    const stepDone =
      typeOfSpace !== "Selecciona una opción" && nameSpace !== "";
    handleComplete(stepDone);
  }, [typeOfSpace, nameSpace, handleComplete]);

  useEffect(() => {
    const stepDone = valiValue(minimalPrice, maximumPrice);
    handleComplete(stepDone);
  }, [minimalPrice, maximumPrice, handleComplete]);

  useEffect(() => {
    const stepDone = valiValue(minimumDuration, maximumDuration);
    handleComplete(stepDone);
  }, [minimumDuration, maximumDuration, handleComplete]);

  useEffect(() => {
    const stepDone =
      valiValue(height, base) &&
      typeOfMaterial !== "Selecciona tipo de material";
    handleComplete(stepDone);
  }, [height, base, typeOfMaterial, handleComplete]);

  useEffect(() => {
    const stepDone = availability.isAvailability !== null;
    handleComplete(stepDone);
  }, [availability, handleComplete]);

  useEffect(() => {
    const stepDone =
      sponsorType.findIndex((elem) => elem.checked === true) !== -1;
    handleComplete(stepDone);
  }, [sponsorType, handleComplete]);

  useEffect(() => {
    const stepDone =
      picture.findIndex((pic) => pic !== null) !== -1 &&
      imgs.findIndex((img) => img !== null) !== -1;
    handleComplete(stepDone);
  }, [picture, imgs, handleComplete]);

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
        className={classes.style}
        connector={<QontoConnector />}
        component="div"
      >
        {steps.map((label, index) => (
          <Step key={label} component="div">
            <StepButton
              onClick={() => handleStep(index)}
              completed={completed[index]}
            >
              <div className="container-title-btn">
                <Typography
                  variant="h6"
                  color="inherit"
                  className="title-btn"
                  component="h6"
                >
                  <span>{label}</span>
                  {activeStep === index ? (
                    <RemoveIcon
                      className="iconLabel"
                      style={{ display: "block" }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      className="iconLabel"
                      style={{ display: "block" }}
                    />
                  )}
                </Typography>{" "}
              </div>
            </StepButton>
            <StepContent>
              <Typography component="div">
                {getStepContent(
                  index,
                  typeOfSpace,
                  minimalPrice,
                  maximumPrice,
                  minimumDuration,
                  maximumDuration,
                  height,
                  base,
                  typeOfMaterial,
                  optionsTypeSpace,
                  optionsMaterial
                )}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
